import React, { useEffect } from 'react';
import { loginByFacebookAccessToken } from '../../../services/auth';

const AuthFacebookCallback = () => {
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    loginByFacebookAccessToken(params.access_token)
      .then(() => {
        window.location.replace('/');
      })
      .catch(() => {
        window.location.replace('/');
      });
  });

  return <div>Loading ...</div>;
};

export default AuthFacebookCallback;
